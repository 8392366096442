// Logo in header
header .navbar-brand {
	max-width: 350px;
	img {
		max-height: 90px!important;
	}
	@include media-breakpoint-down(sm) {
		max-width: 70px;
		padding: 0;
	}
}

// Main Nav Colors
.navbar-dark.bg-dark {
	background-color: #0E0D0D!important;
}
.navbar-sticky.bg-light {
	background-color: #404040!important;
	.navbar-main-links a,
	.navbar-tool-text {
		color: #FFF!important;
	}
	.navbar-tool-text > small,
	.fa-user {
		color: #E7E7E7!important;
	}
	.navbar-tool-icon-box.bg-secondary {
		background-color: #FFF!important;
	}
	.navbar-toggler {
		background-color: #FFF;
		width: $navbar-tool-icon-box-size;
		height: $navbar-tool-icon-box-size;
		border-radius: 50%;
	    order: 3;
		margin-left: 15px;
	}
}

// Body Colors
body {
	background-color: #fafafa!important;
}
.bg-lightgray {
	background-color: #EEEEEE!important;
}

// Footer Colors
footer .bg-primary {
	background-color: $gray-900!important;
}

.addToCartForm {
    display: flex;
    margin: 1rem;
}

.addToCartForm input {
	width: 100% !important;
	padding: 0.625rem;
}

.addToCartForm .position-relative {
    margin-right: 5px;
}

// banner slider CSS
.main-slider {
   height:300px;
}
.main-slider .hero {
   height:300px;
}
.main-banner {
	transform: translateY(10%);
	height: 'fit-content'
}
.slick-dots {
    padding: 1rem 0 !important;
}
.product-card > .btn-verified {
	color : #308D46;
	padding: 0 !important;
    position: absolute;
    top: 0.35rem;
    left: 1rem;
    z-index: 5;
    i {
    	color : #308D46;
    	font-size: 1.10rem;
    	line-height: 2rem;
    }
}
// L&R+ Plus fixes
.landrsalesplus-icon {
	width: 60px;
}
li:has(.widget-list-link .cz-filter-item-text .custom-checkbox label[for="L&RPlus+Noinput"]) {
	display: none;
	visibility: hidden;
}
.carousel-control-next,
   .carousel-control-prev {
  	  background: transparent;
  	  border: 0;
    }
 .nav-tabs {
	line-height: 2.25;
	border: 0;
	.nav-item {
		.nav-link {
			color: $dark;
			font-weight: 500;
			white-space: nowrap;
			width: 100%;
			background-color: $gray-100;
			&:hover {
				background-color: $gray-200;
			}
		}
	}
	.nav-link.active {
		border-width: 0;
		border-bottom-width: 5px;
		background: $white !important;
		border-color: $white $white $primary !important;
	}
}

.brand-header {
	display: flex;
	align-items: center;
  
	.brand-image {
	  width: 100px;
	  height: 100px;
	  background-position: center center;
	  background-size: contain;
	  background-repeat: no-repeat;
	  border: solid 1px #eee;
	}
  
	h2 {
	  padding: 0 15px 0 30px;
	  margin: 0;
	}
  }
  
  .brand-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 15px;
  
	@media(min-width: 1300px) {
	  grid-template-columns: 1fr 1fr;
	}
  
	.brand-list-item {
	  border: solid 1px #eee;
	  padding: 15px;
	  text-decoration: none;
	  border-radius: 8px;
  
	  h6 {
		padding: 0;
		margin: 0 0 10px;
		span{
		  font-size: 80%;
		  float: right;
		  margin: -6px -6px 0 0;
		  color: #666;
		  background: #eee;
		  padding: 0 10px;
		  line-height: 30px;
		  border-radius: 15px;
		}
	  }
  
	  &:hover {
		background: #fafafa;
		border-color: #ccc;
	  }
  
	  .product-type-name-path {
		font-size: 90%;
		color: #666;
		padding: 0;
		margin: 0;
	  }
	}
  }
  
  
  .cz-preview{
  	margin-top: unset !important;
  }
  .form-box-align{
  	margin-left: unset !important
  }
  
   .vendor-child-list{
  	 min-width: 150px;
   }

  @media only screen and (max-width: 768px) {
  .child-block-list {
    display: block;
  }
    .vendor-child-list{
  	width: 100%;
  	align-self: center;
  }
  .cz-product-gallery{
  	display: flex !important;
  }
  .hero .main-banner .repeater{
  	padding: 2rem !important;
  }
}


.lr-productImage{
	max-height: 175px !important;
	top: 35px !important;
}

@media (min-width: 992px) {
    .pdp-attributes {
        -webkit-column-count: 2;
        column-count: 1;
    }
}
